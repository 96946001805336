interface TitleProps {
  color?: string;
  width?: string;
  height?: string;
}

const Title: React.FC<TitleProps> = ({
  color = "white",
  width = "287",
  height = "69",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 287 69"
      fill="none"
    >
      <mask
        id="path-1-outside-1_18_80"
        maskUnits="userSpaceOnUse"
        x="-0.383522"
        y="0"
        width={width}
        height={height}
        fill="black"
      >
        <rect fill={color} x="-0.383522" width={width} height={height} />
        <path d="M34.6736 24.9476C33.3331 26.0351 31.5556 28.5041 30.9437 29.3859C35.2272 26.6818 52.7987 2.93246 46.417 2.13886C36.9174 0.963154 10.4 22.4786 11.1285 31.9724C11.6821 39.203 40.8804 40.6139 33.9159 51.401C29.8363 57.691 10.6914 62.9229 2.96926 60.307C0.200959 59.3958 0.200959 56.9856 1.30828 54.546C1.59968 53.87 3.23152 54.4578 2.79442 55.1927C0.871179 58.4553 1.74538 59.8955 6.08724 59.9249C16.9565 59.9543 31.3808 53.8406 33.3331 49.4905C36.218 42.9947 13.7802 40.5551 9.96286 33.2951C4.71766 23.3604 39.2194 -3.35757 47.4078 1.60979C53.7894 5.48963 35.2564 28.2984 31.2642 31.6785C30.0695 32.7073 28.6707 31.2082 29.0787 30.444C29.9529 28.7392 33.0126 25.0358 34.2948 24.3009C34.6444 24.0952 35.0524 24.6243 34.6736 24.9476Z" />
        <path d="M46.6793 21.7144C47.0581 20.862 48.7482 21.8907 48.2237 22.7725C47.3495 24.1834 46.213 26.0351 45.4262 27.2108C45.1931 27.5635 44.3189 27.152 44.5229 26.6524C45.2514 24.83 46.0965 22.9783 46.6793 21.7144ZM41.9003 45.9928C40.2685 46.8451 38.1995 45.4931 37.9373 43.7589C37.3545 39.9966 41.1135 32.5309 43.095 28.7392C43.6487 27.9163 45.2514 29.1213 44.7269 29.8562C42.9785 32.3252 37.5293 43.8471 40.1228 45.0522C42.2208 46.0515 51.4291 33.7948 53.1483 31.2964C53.6729 30.8849 54.3431 31.6785 53.906 32.1488C50.7297 36.058 44.7852 44.5231 41.9003 45.9928Z" />
        <path d="M51.8953 65.3037C44.2606 71.0647 39.4234 67.1261 43.3282 57.9262C47.9906 46.9627 58.277 38.527 66.6984 33.9124C66.6984 32.2664 66.4945 27.7987 65.0666 27.299C62.6188 26.476 53.2066 42.0835 50.0886 42.2011C45.9216 42.3481 53.1775 26.0351 60.6373 26.0645C62.1526 26.0645 62.5897 26.7111 63.1142 26.329C63.9593 25.653 64.8626 24.8888 65.8534 25.2709C67.8932 25.9763 68.5343 30.5028 68.6508 33.0012C70.6906 32.1782 75.0325 31.1494 76.4021 31.6491C76.7809 31.9724 76.6352 32.2664 76.1689 32.0606C74.2457 32.1782 70.8363 33.1188 68.9131 34.1769C68.7091 34.3239 68.5925 34.7354 68.5634 35.0293C67.1647 48.256 59.53 59.5134 51.8953 65.3037ZM50.2926 40.7609C52.2159 40.3787 58.8598 30.7086 62.1526 27.2108C57.1405 26.0351 50.7589 36.3813 50.2926 40.7609ZM44.4938 58.3377C40.5307 67.2731 44.5229 70.0066 51.4582 64.128C59.6174 57.1914 65.358 44.8464 66.5236 35.7641C66.5819 35.3526 66.4945 35.2644 66.1448 35.4702C58.5392 39.7909 48.282 49.7844 44.4938 58.3377Z" />
        <path d="M86.0183 48.2266C79.7532 46.9333 89.1945 28.269 89.5733 26.1527C89.6899 25.5354 89.2237 25.8294 88.9614 26.0939C82.842 32.3252 76.9849 40.3494 73.6046 46.0809C73.1092 46.9039 71.186 46.4336 71.2734 45.787C72.0602 40.9078 75.2947 32.0312 78.5876 24.4185C78.8498 23.8601 80.3651 24.5067 80.2485 25.0064C76.9266 31.943 74.4788 38.7328 73.5755 42.7008C73.3132 43.8765 73.4589 43.5238 73.896 42.7889C77.5968 37.1749 82.8711 30.591 88.7283 24.6831C89.719 23.7131 91.9628 24.4185 91.7006 25.4473C90.9138 28.5923 81.6473 47.1685 86.4845 47.0509C87.3878 47.0215 86.8342 48.3736 86.0183 48.2266Z" />
        <path d="M120.607 18.0403C119.267 20.3623 117.956 22.655 116.761 25.0946C111.982 34.9999 107.786 46.0515 108.777 52.0476C108.922 52.8412 109.272 52.7237 109.913 52.4591C110.642 52.1946 110.525 54.0463 109.359 54.2227C108.689 54.3109 107.64 53.8406 107.407 52.4298C106.562 47.6681 108.835 39.7615 112.536 30.9731C108.34 37.5277 103.735 44.6995 102.424 45.4343C100.821 46.2867 98.9273 45.4049 98.7233 43.9647C98.461 42.0835 101.025 34.7941 102.424 31.4434C98.5776 36.9104 93.7112 44.4937 92.2542 45.3755C90.7098 46.3161 89.2237 45.2873 89.0197 43.8765C88.7866 42.0247 90.7389 36.7928 93.5947 32.5603C96.4212 28.3571 98.869 26.2703 100.967 26.3878C102.249 26.4466 103.153 27.446 103.473 28.2396C104.289 27.0345 104.784 26.4172 105.105 25.9469C105.6 25.4473 106.737 26.1527 106.387 26.623C104.93 29.3859 98.8399 43.9353 101.025 44.6407C101.958 44.9346 109.097 34.6472 114.605 25.7706C116.003 23.5073 117.402 20.7444 118.772 17.9521C110.554 17.6288 100.676 18.393 96.7709 22.0377C96.2755 22.508 96.8875 22.5962 97.412 23.0665C97.9365 23.478 95.9259 24.4479 95.4888 23.478C95.2556 22.8901 95.2556 21.7438 96.1298 20.9208C100.064 17.1585 109.534 16.1886 119.5 16.4825C120.462 14.5426 121.394 12.6321 122.327 10.8979C122.939 9.81035 124.745 10.5746 124.104 11.7797C123.23 13.455 122.356 15.0129 121.453 16.5413C123.725 16.6589 125.969 16.8352 128.184 17.041C128.912 17.0997 128.33 18.8927 127.688 18.8045C125.794 18.4812 123.347 18.1873 120.607 18.0403ZM101.55 30.9731C102.774 29.1801 102.22 27.3872 101.171 27.2108C100.151 27.0345 97.7617 29.3859 95.3722 33.1481C92.2834 38.0567 89.4568 44.288 91.0595 44.7289C91.7588 44.9052 97.7034 36.5871 101.55 30.9731Z" />
        <path d="M126.377 43.1711C125.882 40.849 127.135 36.3226 128.213 32.6485C124.804 38.2919 120.607 46.2279 119.18 46.9627C117.664 47.7269 115.974 46.1691 115.566 44.4055C114.75 40.6727 119.15 31.6491 120.695 28.6805C121.19 27.9456 122.822 29.2389 122.385 29.9443C121.365 31.3846 115.158 44.5525 117.839 45.9046C119.005 46.4924 128.825 28.9744 130.34 26.7111C130.777 26.1821 132.322 27.4754 132.001 27.975C130.981 29.4153 125.998 43.2004 128.65 44.6407C130.806 45.8164 138.237 33.06 139.752 30.7967C140.248 30.4146 141.064 31.3552 140.656 31.7667C138.354 35.1469 133.546 44.1116 130.486 45.7282C128.971 46.5512 126.785 44.9052 126.377 43.1711Z" />
        <path d="M145.289 25.2415C141.122 28.8862 136.226 42.3187 136.78 43.8765C139.315 38.2037 143.511 28.945 148.64 24.3009C149.573 23.4192 151.467 24.2716 151.234 25.5354C151 26.9757 145.697 36.2638 147.795 36.6459C148.902 36.8222 153.04 33.3245 154.235 31.5315C154.556 31.0613 155.43 31.6197 155.022 32.09C153.652 33.5596 148.757 38.9973 146.338 37.6158C143.832 36.1756 149.165 27.2402 149.573 25.2121C145.406 27.5048 139.024 41.0254 136.984 47.0509C136.605 48.1384 134.536 47.6975 134.595 46.61C134.886 38.9385 140.073 28.269 144.211 24.4479C144.619 24.007 145.755 24.8006 145.289 25.2415Z" />
        <path d="M154.41 36.3226C156.304 36.352 159.772 30.9731 160.646 27.1226C160.762 26.623 160.762 26.2997 160.209 26.8875C156.858 30.2383 151.758 39.203 152.632 43.9647C152.895 45.4931 154.177 44.9934 155.663 43.8471C159.742 40.6727 164.551 34.7354 168.077 30.8555C168.572 30.4146 169.3 31.0613 168.892 31.5315C166.532 34.2063 158.402 44.288 155.43 45.5519C153.74 46.2867 151.146 45.9634 150.826 43.994C149.922 38.2919 154.818 29.092 158.839 25.7412C160.034 24.7418 162.452 25.8882 161.986 27.6811C161.374 29.9737 157.994 37.9098 154.614 36.8222C154.118 36.6753 153.973 36.3226 154.41 36.3226Z" />
        <path d="M178.829 42.4362C178.042 43.2886 176.585 41.1136 178.8 40.0848C181.306 38.9679 186.755 37.9979 189.116 37.6452C189.524 37.5864 189.728 37.3807 189.873 37.0574C192.671 31.3258 201.791 12.8966 206.745 10.0455C208.552 9.01675 210.213 10.7215 210.038 12.3381C209.572 16.747 205.871 24.3009 204.064 28.3571C203.86 28.798 202.87 28.2984 203.015 27.8869C204.618 24.0658 207.823 17.2467 208.581 13.1023C209.047 10.5452 208.348 10.9273 206.774 12.4851C202.083 16.3649 196.459 27.3284 191.971 36.6753C191.709 37.2043 192.234 37.0574 192.525 37.028C196.546 36.5283 203.511 35.5583 206.716 35.529C207.357 35.529 207.27 36.8222 206.716 36.8222C202.374 36.9398 196.138 37.9392 191.767 38.527C191.272 38.6152 190.981 38.8209 190.777 39.2618C189.902 42.1423 182.355 57.2502 186.027 58.3671C188.591 59.1313 200.859 55.4278 209.368 53.1646C209.922 53.0176 210.534 53.1646 211.058 53.5761C212.02 54.4285 211.233 55.6042 210.767 55.1045C210.213 54.5166 210.126 54.1639 209.193 54.4578C203.161 56.4566 188.212 61.2182 184.803 59.4546C180.257 57.1032 187.542 43.2298 188.475 40.173C188.708 39.4382 188.766 39.2912 188.271 39.3794C186.085 39.6733 180.257 40.9078 178.829 42.4362ZM226.561 6.95926C227.668 7.31198 228.484 8.811 227.318 9.39886C226.794 9.634 226.357 8.95797 226.648 8.66404C227.085 8.28193 227.318 8.19376 226.648 7.92922C219.479 5.37206 194.332 11.427 189.524 14.2487C188.795 14.6601 189.349 14.7189 189.757 14.7777C191.243 15.0423 190.864 16.4825 189.961 16.3061C187.309 15.8065 187.134 13.749 188.3 12.9554C193.632 9.31068 218.751 4.31392 226.561 6.95926Z" />
        <path d="M226.735 24.2128C227.231 23.6249 228.892 24.3891 228.513 24.9476C222.423 35.2644 215.691 44.2292 212.981 46.1397C211.262 47.3154 209.484 46.4043 209.28 45.2579C208.727 41.9953 213.36 32.0606 215.167 28.9156C215.75 28.0632 217.556 28.7686 217.061 29.5622C215.866 31.1788 209.28 45.4931 211.495 45.5519C212.836 45.5813 218.489 39.0855 226.735 24.2128Z" />
        <path d="M229.999 36.3226C231.893 36.352 235.361 30.9731 236.235 27.1226C236.352 26.623 236.352 26.2997 235.798 26.8875C232.447 30.2383 227.347 39.203 228.221 43.9647C228.484 45.4931 229.766 44.9934 231.252 43.8471C235.332 40.6727 240.14 34.7354 243.666 30.8555C244.161 30.4146 244.89 31.0613 244.482 31.5315C242.121 34.2063 233.991 44.288 231.019 45.5519C229.329 46.2867 226.735 45.9634 226.415 43.994C225.511 38.2919 230.407 29.092 234.428 25.7412C235.623 24.7418 238.042 25.8882 237.575 27.6811C236.963 29.9737 233.583 37.9098 230.203 36.8222C229.708 36.6753 229.562 36.3226 229.999 36.3226Z" />
        <path d="M253.224 48.2266C246.959 46.9333 256.4 28.269 256.779 26.1527C256.895 25.5354 256.429 25.8294 256.167 26.0939C250.047 32.3252 244.19 40.3494 240.81 46.0809C240.315 46.9039 238.391 46.4336 238.479 45.787C239.266 40.9078 242.5 32.0312 245.793 24.4185C246.055 23.8601 247.57 24.5067 247.454 25.0064C244.132 31.943 241.684 38.7328 240.781 42.7008C240.519 43.8765 240.664 43.5238 241.101 42.7889C244.802 37.1749 250.076 30.591 255.934 24.6831C256.924 23.7131 259.168 24.4185 258.906 25.4473C258.119 28.5923 248.853 47.1685 253.69 47.0509C254.593 47.0215 254.04 48.3736 253.224 48.2266Z" />
        <path d="M272.777 12.8378C272.194 13.8372 271.553 14.9247 270.882 16.1592H279.799C280.44 16.1592 280.12 17.5994 279.595 17.5994H270.067C264.005 28.8568 255.904 47.1979 258.003 53.4585C258.177 54.3109 258.527 54.1639 259.197 53.8994C259.926 53.6349 259.838 55.5748 258.644 55.7217C257.944 55.8099 256.837 55.3396 256.575 53.8406C254.273 47.0803 262.199 29.3859 268.143 17.5994H259.226C258.469 17.5994 258.731 16.1592 259.46 16.1592H268.901C269.659 14.6601 270.387 13.2787 271.028 12.0736C271.524 11.133 273.272 12.0442 272.777 12.8378Z" />
        <path d="M285.686 31.414C282.772 33.2363 279.712 35.0293 276.944 37.028C276.332 37.4689 276.273 37.7922 276.419 38.2625C277.293 41.4369 274.583 46.3455 271.524 48.932C265.987 53.6349 262.84 49.9608 267.619 44.0822C269.717 41.5251 272.631 39.0561 274.612 37.5571C273.592 34.3826 268.755 33.06 268.784 29.9737C268.813 26.1821 273.418 22.3316 276.332 21.5674C277.847 21.1559 279.158 22.0965 278.925 23.3898C278.721 24.3303 277.556 26.9463 276.39 29.2095C276.215 29.5622 275.691 29.2683 275.807 28.945C276.215 27.7987 277.934 22.8607 277.177 22.508C276.215 22.0671 270.766 25.653 270.591 29.8562C270.474 32.4133 273.651 33.6772 275.749 36.5577C280.091 33.5596 282.276 32.237 285.132 30.591C285.569 30.3265 286.123 31.1788 285.686 31.414ZM270.708 47.6975C273.301 45.1698 275.487 40.8784 274.962 38.7621C273.826 39.4088 270.562 42.3481 268.406 45.1698C264.821 49.902 266.57 51.7831 270.708 47.6975Z" />
      </mask>
      <path
        d="M34.6736 24.9476C33.3331 26.0351 31.5556 28.5041 30.9437 29.3859C35.2272 26.6818 52.7987 2.93246 46.417 2.13886C36.9174 0.963154 10.4 22.4786 11.1285 31.9724C11.6821 39.203 40.8804 40.6139 33.9159 51.401C29.8363 57.691 10.6914 62.9229 2.96926 60.307C0.200959 59.3958 0.200959 56.9856 1.30828 54.546C1.59968 53.87 3.23152 54.4578 2.79442 55.1927C0.871179 58.4553 1.74538 59.8955 6.08724 59.9249C16.9565 59.9543 31.3808 53.8406 33.3331 49.4905C36.218 42.9947 13.7802 40.5551 9.96286 33.2951C4.71766 23.3604 39.2194 -3.35757 47.4078 1.60979C53.7894 5.48963 35.2564 28.2984 31.2642 31.6785C30.0695 32.7073 28.6707 31.2082 29.0787 30.444C29.9529 28.7392 33.0126 25.0358 34.2948 24.3009C34.6444 24.0952 35.0524 24.6243 34.6736 24.9476Z"
        fill={color}
      />
      <path
        d="M46.6793 21.7144C47.0581 20.862 48.7482 21.8907 48.2237 22.7725C47.3495 24.1834 46.213 26.0351 45.4262 27.2108C45.1931 27.5635 44.3189 27.152 44.5229 26.6524C45.2514 24.83 46.0965 22.9783 46.6793 21.7144ZM41.9003 45.9928C40.2685 46.8451 38.1995 45.4931 37.9373 43.7589C37.3545 39.9966 41.1135 32.5309 43.095 28.7392C43.6487 27.9163 45.2514 29.1213 44.7269 29.8562C42.9785 32.3252 37.5293 43.8471 40.1228 45.0522C42.2208 46.0515 51.4291 33.7948 53.1483 31.2964C53.6729 30.8849 54.3431 31.6785 53.906 32.1488C50.7297 36.058 44.7852 44.5231 41.9003 45.9928Z"
        fill={color}
      />
      <path
        d="M51.8953 65.3037C44.2606 71.0647 39.4234 67.1261 43.3282 57.9262C47.9906 46.9627 58.277 38.527 66.6984 33.9124C66.6984 32.2664 66.4945 27.7987 65.0666 27.299C62.6188 26.476 53.2066 42.0835 50.0886 42.2011C45.9216 42.3481 53.1775 26.0351 60.6373 26.0645C62.1526 26.0645 62.5897 26.7111 63.1142 26.329C63.9593 25.653 64.8626 24.8888 65.8534 25.2709C67.8932 25.9763 68.5343 30.5028 68.6508 33.0012C70.6906 32.1782 75.0325 31.1494 76.4021 31.6491C76.7809 31.9724 76.6352 32.2664 76.1689 32.0606C74.2457 32.1782 70.8363 33.1188 68.9131 34.1769C68.7091 34.3239 68.5925 34.7354 68.5634 35.0293C67.1647 48.256 59.53 59.5134 51.8953 65.3037ZM50.2926 40.7609C52.2159 40.3787 58.8598 30.7086 62.1526 27.2108C57.1405 26.0351 50.7589 36.3813 50.2926 40.7609ZM44.4938 58.3377C40.5307 67.2731 44.5229 70.0066 51.4582 64.128C59.6174 57.1914 65.358 44.8464 66.5236 35.7641C66.5819 35.3526 66.4945 35.2644 66.1448 35.4702C58.5392 39.7909 48.282 49.7844 44.4938 58.3377Z"
        fill={color}
      />
      <path
        d="M86.0183 48.2266C79.7532 46.9333 89.1945 28.269 89.5733 26.1527C89.6899 25.5354 89.2237 25.8294 88.9614 26.0939C82.842 32.3252 76.9849 40.3494 73.6046 46.0809C73.1092 46.9039 71.186 46.4336 71.2734 45.787C72.0602 40.9078 75.2947 32.0312 78.5876 24.4185C78.8498 23.8601 80.3651 24.5067 80.2485 25.0064C76.9266 31.943 74.4788 38.7328 73.5755 42.7008C73.3132 43.8765 73.4589 43.5238 73.896 42.7889C77.5968 37.1749 82.8711 30.591 88.7283 24.6831C89.719 23.7131 91.9628 24.4185 91.7006 25.4473C90.9138 28.5923 81.6473 47.1685 86.4845 47.0509C87.3878 47.0215 86.8342 48.3736 86.0183 48.2266Z"
        fill={color}
      />
      <path
        d="M120.607 18.0403C119.267 20.3623 117.956 22.655 116.761 25.0946C111.982 34.9999 107.786 46.0515 108.777 52.0476C108.922 52.8412 109.272 52.7237 109.913 52.4591C110.642 52.1946 110.525 54.0463 109.359 54.2227C108.689 54.3109 107.64 53.8406 107.407 52.4298C106.562 47.6681 108.835 39.7615 112.536 30.9731C108.34 37.5277 103.735 44.6995 102.424 45.4343C100.821 46.2867 98.9273 45.4049 98.7233 43.9647C98.461 42.0835 101.025 34.7941 102.424 31.4434C98.5776 36.9104 93.7112 44.4937 92.2542 45.3755C90.7098 46.3161 89.2237 45.2873 89.0197 43.8765C88.7866 42.0247 90.7389 36.7928 93.5947 32.5603C96.4212 28.3571 98.869 26.2703 100.967 26.3878C102.249 26.4466 103.153 27.446 103.473 28.2396C104.289 27.0345 104.784 26.4172 105.105 25.9469C105.6 25.4473 106.737 26.1527 106.387 26.623C104.93 29.3859 98.8399 43.9353 101.025 44.6407C101.958 44.9346 109.097 34.6472 114.605 25.7706C116.003 23.5073 117.402 20.7444 118.772 17.9521C110.554 17.6288 100.676 18.393 96.7709 22.0377C96.2755 22.508 96.8875 22.5962 97.412 23.0665C97.9365 23.478 95.9259 24.4479 95.4888 23.478C95.2556 22.8901 95.2556 21.7438 96.1298 20.9208C100.064 17.1585 109.534 16.1886 119.5 16.4825C120.462 14.5426 121.394 12.6321 122.327 10.8979C122.939 9.81035 124.745 10.5746 124.104 11.7797C123.23 13.455 122.356 15.0129 121.453 16.5413C123.725 16.6589 125.969 16.8352 128.184 17.041C128.912 17.0997 128.33 18.8927 127.688 18.8045C125.794 18.4812 123.347 18.1873 120.607 18.0403ZM101.55 30.9731C102.774 29.1801 102.22 27.3872 101.171 27.2108C100.151 27.0345 97.7617 29.3859 95.3722 33.1481C92.2834 38.0567 89.4568 44.288 91.0595 44.7289C91.7588 44.9052 97.7034 36.5871 101.55 30.9731Z"
        fill={color}
      />
      <path
        d="M126.377 43.1711C125.882 40.849 127.135 36.3226 128.213 32.6485C124.804 38.2919 120.607 46.2279 119.18 46.9627C117.664 47.7269 115.974 46.1691 115.566 44.4055C114.75 40.6727 119.15 31.6491 120.695 28.6805C121.19 27.9456 122.822 29.2389 122.385 29.9443C121.365 31.3846 115.158 44.5525 117.839 45.9046C119.005 46.4924 128.825 28.9744 130.34 26.7111C130.777 26.1821 132.322 27.4754 132.001 27.975C130.981 29.4153 125.998 43.2004 128.65 44.6407C130.806 45.8164 138.237 33.06 139.752 30.7967C140.248 30.4146 141.064 31.3552 140.656 31.7667C138.354 35.1469 133.546 44.1116 130.486 45.7282C128.971 46.5512 126.785 44.9052 126.377 43.1711Z"
        fill={color}
      />
      <path
        d="M145.289 25.2415C141.122 28.8862 136.226 42.3187 136.78 43.8765C139.315 38.2037 143.511 28.945 148.64 24.3009C149.573 23.4192 151.467 24.2716 151.234 25.5354C151 26.9757 145.697 36.2638 147.795 36.6459C148.902 36.8222 153.04 33.3245 154.235 31.5315C154.556 31.0613 155.43 31.6197 155.022 32.09C153.652 33.5596 148.757 38.9973 146.338 37.6158C143.832 36.1756 149.165 27.2402 149.573 25.2121C145.406 27.5048 139.024 41.0254 136.984 47.0509C136.605 48.1384 134.536 47.6975 134.595 46.61C134.886 38.9385 140.073 28.269 144.211 24.4479C144.619 24.007 145.755 24.8006 145.289 25.2415Z"
        fill={color}
      />
      <path
        d="M154.41 36.3226C156.304 36.352 159.772 30.9731 160.646 27.1226C160.762 26.623 160.762 26.2997 160.209 26.8875C156.858 30.2383 151.758 39.203 152.632 43.9647C152.895 45.4931 154.177 44.9934 155.663 43.8471C159.742 40.6727 164.551 34.7354 168.077 30.8555C168.572 30.4146 169.3 31.0613 168.892 31.5315C166.532 34.2063 158.402 44.288 155.43 45.5519C153.74 46.2867 151.146 45.9634 150.826 43.994C149.922 38.2919 154.818 29.092 158.839 25.7412C160.034 24.7418 162.452 25.8882 161.986 27.6811C161.374 29.9737 157.994 37.9098 154.614 36.8222C154.118 36.6753 153.973 36.3226 154.41 36.3226Z"
        fill={color}
      />
      <path
        d="M178.829 42.4362C178.042 43.2886 176.585 41.1136 178.8 40.0848C181.306 38.9679 186.755 37.9979 189.116 37.6452C189.524 37.5864 189.728 37.3807 189.873 37.0574C192.671 31.3258 201.791 12.8966 206.745 10.0455C208.552 9.01675 210.213 10.7215 210.038 12.3381C209.572 16.747 205.871 24.3009 204.064 28.3571C203.86 28.798 202.87 28.2984 203.015 27.8869C204.618 24.0658 207.823 17.2467 208.581 13.1023C209.047 10.5452 208.348 10.9273 206.774 12.4851C202.083 16.3649 196.459 27.3284 191.971 36.6753C191.709 37.2043 192.234 37.0574 192.525 37.028C196.546 36.5283 203.511 35.5583 206.716 35.529C207.357 35.529 207.27 36.8222 206.716 36.8222C202.374 36.9398 196.138 37.9392 191.767 38.527C191.272 38.6152 190.981 38.8209 190.777 39.2618C189.902 42.1423 182.355 57.2502 186.027 58.3671C188.591 59.1313 200.859 55.4278 209.368 53.1646C209.922 53.0176 210.534 53.1646 211.058 53.5761C212.02 54.4285 211.233 55.6042 210.767 55.1045C210.213 54.5166 210.126 54.1639 209.193 54.4578C203.161 56.4566 188.212 61.2182 184.803 59.4546C180.257 57.1032 187.542 43.2298 188.475 40.173C188.708 39.4382 188.766 39.2912 188.271 39.3794C186.085 39.6733 180.257 40.9078 178.829 42.4362ZM226.561 6.95926C227.668 7.31198 228.484 8.811 227.318 9.39886C226.794 9.634 226.357 8.95797 226.648 8.66404C227.085 8.28193 227.318 8.19376 226.648 7.92922C219.479 5.37206 194.332 11.427 189.524 14.2487C188.795 14.6601 189.349 14.7189 189.757 14.7777C191.243 15.0423 190.864 16.4825 189.961 16.3061C187.309 15.8065 187.134 13.749 188.3 12.9554C193.632 9.31068 218.751 4.31392 226.561 6.95926Z"
        fill={color}
      />
      <path
        d="M226.735 24.2128C227.231 23.6249 228.892 24.3891 228.513 24.9476C222.423 35.2644 215.691 44.2292 212.981 46.1397C211.262 47.3154 209.484 46.4043 209.28 45.2579C208.727 41.9953 213.36 32.0606 215.167 28.9156C215.75 28.0632 217.556 28.7686 217.061 29.5622C215.866 31.1788 209.28 45.4931 211.495 45.5519C212.836 45.5813 218.489 39.0855 226.735 24.2128Z"
        fill={color}
      />
      <path
        d="M229.999 36.3226C231.893 36.352 235.361 30.9731 236.235 27.1226C236.352 26.623 236.352 26.2997 235.798 26.8875C232.447 30.2383 227.347 39.203 228.221 43.9647C228.484 45.4931 229.766 44.9934 231.252 43.8471C235.332 40.6727 240.14 34.7354 243.666 30.8555C244.161 30.4146 244.89 31.0613 244.482 31.5315C242.121 34.2063 233.991 44.288 231.019 45.5519C229.329 46.2867 226.735 45.9634 226.415 43.994C225.511 38.2919 230.407 29.092 234.428 25.7412C235.623 24.7418 238.042 25.8882 237.575 27.6811C236.963 29.9737 233.583 37.9098 230.203 36.8222C229.708 36.6753 229.562 36.3226 229.999 36.3226Z"
        fill={color}
      />
      <path
        d="M253.224 48.2266C246.959 46.9333 256.4 28.269 256.779 26.1527C256.895 25.5354 256.429 25.8294 256.167 26.0939C250.047 32.3252 244.19 40.3494 240.81 46.0809C240.315 46.9039 238.391 46.4336 238.479 45.787C239.266 40.9078 242.5 32.0312 245.793 24.4185C246.055 23.8601 247.57 24.5067 247.454 25.0064C244.132 31.943 241.684 38.7328 240.781 42.7008C240.519 43.8765 240.664 43.5238 241.101 42.7889C244.802 37.1749 250.076 30.591 255.934 24.6831C256.924 23.7131 259.168 24.4185 258.906 25.4473C258.119 28.5923 248.853 47.1685 253.69 47.0509C254.593 47.0215 254.04 48.3736 253.224 48.2266Z"
        fill={color}
      />
      <path
        d="M272.777 12.8378C272.194 13.8372 271.553 14.9247 270.882 16.1592H279.799C280.44 16.1592 280.12 17.5994 279.595 17.5994H270.067C264.005 28.8568 255.904 47.1979 258.003 53.4585C258.177 54.3109 258.527 54.1639 259.197 53.8994C259.926 53.6349 259.838 55.5748 258.644 55.7217C257.944 55.8099 256.837 55.3396 256.575 53.8406C254.273 47.0803 262.199 29.3859 268.143 17.5994H259.226C258.469 17.5994 258.731 16.1592 259.46 16.1592H268.901C269.659 14.6601 270.387 13.2787 271.028 12.0736C271.524 11.133 273.272 12.0442 272.777 12.8378Z"
        fill={color}
      />
      <path
        d="M285.686 31.414C282.772 33.2363 279.712 35.0293 276.944 37.028C276.332 37.4689 276.273 37.7922 276.419 38.2625C277.293 41.4369 274.583 46.3455 271.524 48.932C265.987 53.6349 262.84 49.9608 267.619 44.0822C269.717 41.5251 272.631 39.0561 274.612 37.5571C273.592 34.3826 268.755 33.06 268.784 29.9737C268.813 26.1821 273.418 22.3316 276.332 21.5674C277.847 21.1559 279.158 22.0965 278.925 23.3898C278.721 24.3303 277.556 26.9463 276.39 29.2095C276.215 29.5622 275.691 29.2683 275.807 28.945C276.215 27.7987 277.934 22.8607 277.177 22.508C276.215 22.0671 270.766 25.653 270.591 29.8562C270.474 32.4133 273.651 33.6772 275.749 36.5577C280.091 33.5596 282.276 32.237 285.132 30.591C285.569 30.3265 286.123 31.1788 285.686 31.414ZM270.708 47.6975C273.301 45.1698 275.487 40.8784 274.962 38.7621C273.826 39.4088 270.562 42.3481 268.406 45.1698C264.821 49.902 266.57 51.7831 270.708 47.6975Z"
        fill={color}
      />
      <path
        d="M34.6736 24.9476C33.3331 26.0351 31.5556 28.5041 30.9437 29.3859C35.2272 26.6818 52.7987 2.93246 46.417 2.13886C36.9174 0.963154 10.4 22.4786 11.1285 31.9724C11.6821 39.203 40.8804 40.6139 33.9159 51.401C29.8363 57.691 10.6914 62.9229 2.96926 60.307C0.200959 59.3958 0.200959 56.9856 1.30828 54.546C1.59968 53.87 3.23152 54.4578 2.79442 55.1927C0.871179 58.4553 1.74538 59.8955 6.08724 59.9249C16.9565 59.9543 31.3808 53.8406 33.3331 49.4905C36.218 42.9947 13.7802 40.5551 9.96286 33.2951C4.71766 23.3604 39.2194 -3.35757 47.4078 1.60979C53.7894 5.48963 35.2564 28.2984 31.2642 31.6785C30.0695 32.7073 28.6707 31.2082 29.0787 30.444C29.9529 28.7392 33.0126 25.0358 34.2948 24.3009C34.6444 24.0952 35.0524 24.6243 34.6736 24.9476Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M46.6793 21.7144C47.0581 20.862 48.7482 21.8907 48.2237 22.7725C47.3495 24.1834 46.213 26.0351 45.4262 27.2108C45.1931 27.5635 44.3189 27.152 44.5229 26.6524C45.2514 24.83 46.0965 22.9783 46.6793 21.7144ZM41.9003 45.9928C40.2685 46.8451 38.1995 45.4931 37.9373 43.7589C37.3545 39.9966 41.1135 32.5309 43.095 28.7392C43.6487 27.9163 45.2514 29.1213 44.7269 29.8562C42.9785 32.3252 37.5293 43.8471 40.1228 45.0522C42.2208 46.0515 51.4291 33.7948 53.1483 31.2964C53.6729 30.8849 54.3431 31.6785 53.906 32.1488C50.7297 36.058 44.7852 44.5231 41.9003 45.9928Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M51.8953 65.3037C44.2606 71.0647 39.4234 67.1261 43.3282 57.9262C47.9906 46.9627 58.277 38.527 66.6984 33.9124C66.6984 32.2664 66.4945 27.7987 65.0666 27.299C62.6188 26.476 53.2066 42.0835 50.0886 42.2011C45.9216 42.3481 53.1775 26.0351 60.6373 26.0645C62.1526 26.0645 62.5897 26.7111 63.1142 26.329C63.9593 25.653 64.8626 24.8888 65.8534 25.2709C67.8932 25.9763 68.5343 30.5028 68.6508 33.0012C70.6906 32.1782 75.0325 31.1494 76.4021 31.6491C76.7809 31.9724 76.6352 32.2664 76.1689 32.0606C74.2457 32.1782 70.8363 33.1188 68.9131 34.1769C68.7091 34.3239 68.5925 34.7354 68.5634 35.0293C67.1647 48.256 59.53 59.5134 51.8953 65.3037ZM50.2926 40.7609C52.2159 40.3787 58.8598 30.7086 62.1526 27.2108C57.1405 26.0351 50.7589 36.3813 50.2926 40.7609ZM44.4938 58.3377C40.5307 67.2731 44.5229 70.0066 51.4582 64.128C59.6174 57.1914 65.358 44.8464 66.5236 35.7641C66.5819 35.3526 66.4945 35.2644 66.1448 35.4702C58.5392 39.7909 48.282 49.7844 44.4938 58.3377Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M86.0183 48.2266C79.7532 46.9333 89.1945 28.269 89.5733 26.1527C89.6899 25.5354 89.2237 25.8294 88.9614 26.0939C82.842 32.3252 76.9849 40.3494 73.6046 46.0809C73.1092 46.9039 71.186 46.4336 71.2734 45.787C72.0602 40.9078 75.2947 32.0312 78.5876 24.4185C78.8498 23.8601 80.3651 24.5067 80.2485 25.0064C76.9266 31.943 74.4788 38.7328 73.5755 42.7008C73.3132 43.8765 73.4589 43.5238 73.896 42.7889C77.5968 37.1749 82.8711 30.591 88.7283 24.6831C89.719 23.7131 91.9628 24.4185 91.7006 25.4473C90.9138 28.5923 81.6473 47.1685 86.4845 47.0509C87.3878 47.0215 86.8342 48.3736 86.0183 48.2266Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M120.607 18.0403C119.267 20.3623 117.956 22.655 116.761 25.0946C111.982 34.9999 107.786 46.0515 108.777 52.0476C108.922 52.8412 109.272 52.7237 109.913 52.4591C110.642 52.1946 110.525 54.0463 109.359 54.2227C108.689 54.3109 107.64 53.8406 107.407 52.4298C106.562 47.6681 108.835 39.7615 112.536 30.9731C108.34 37.5277 103.735 44.6995 102.424 45.4343C100.821 46.2867 98.9273 45.4049 98.7233 43.9647C98.461 42.0835 101.025 34.7941 102.424 31.4434C98.5776 36.9104 93.7112 44.4937 92.2542 45.3755C90.7098 46.3161 89.2237 45.2873 89.0197 43.8765C88.7866 42.0247 90.7389 36.7928 93.5947 32.5603C96.4212 28.3571 98.869 26.2703 100.967 26.3878C102.249 26.4466 103.153 27.446 103.473 28.2396C104.289 27.0345 104.784 26.4172 105.105 25.9469C105.6 25.4473 106.737 26.1527 106.387 26.623C104.93 29.3859 98.8399 43.9353 101.025 44.6407C101.958 44.9346 109.097 34.6472 114.605 25.7706C116.003 23.5073 117.402 20.7444 118.772 17.9521C110.554 17.6288 100.676 18.393 96.7709 22.0377C96.2755 22.508 96.8875 22.5962 97.412 23.0665C97.9365 23.478 95.9259 24.4479 95.4888 23.478C95.2556 22.8901 95.2556 21.7438 96.1298 20.9208C100.064 17.1585 109.534 16.1886 119.5 16.4825C120.462 14.5426 121.394 12.6321 122.327 10.8979C122.939 9.81035 124.745 10.5746 124.104 11.7797C123.23 13.455 122.356 15.0129 121.453 16.5413C123.725 16.6589 125.969 16.8352 128.184 17.041C128.912 17.0997 128.33 18.8927 127.688 18.8045C125.794 18.4812 123.347 18.1873 120.607 18.0403ZM101.55 30.9731C102.774 29.1801 102.22 27.3872 101.171 27.2108C100.151 27.0345 97.7617 29.3859 95.3722 33.1481C92.2834 38.0567 89.4568 44.288 91.0595 44.7289C91.7588 44.9052 97.7034 36.5871 101.55 30.9731Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M126.377 43.1711C125.882 40.849 127.135 36.3226 128.213 32.6485C124.804 38.2919 120.607 46.2279 119.18 46.9627C117.664 47.7269 115.974 46.1691 115.566 44.4055C114.75 40.6727 119.15 31.6491 120.695 28.6805C121.19 27.9456 122.822 29.2389 122.385 29.9443C121.365 31.3846 115.158 44.5525 117.839 45.9046C119.005 46.4924 128.825 28.9744 130.34 26.7111C130.777 26.1821 132.322 27.4754 132.001 27.975C130.981 29.4153 125.998 43.2004 128.65 44.6407C130.806 45.8164 138.237 33.06 139.752 30.7967C140.248 30.4146 141.064 31.3552 140.656 31.7667C138.354 35.1469 133.546 44.1116 130.486 45.7282C128.971 46.5512 126.785 44.9052 126.377 43.1711Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M145.289 25.2415C141.122 28.8862 136.226 42.3187 136.78 43.8765C139.315 38.2037 143.511 28.945 148.64 24.3009C149.573 23.4192 151.467 24.2716 151.234 25.5354C151 26.9757 145.697 36.2638 147.795 36.6459C148.902 36.8222 153.04 33.3245 154.235 31.5315C154.556 31.0613 155.43 31.6197 155.022 32.09C153.652 33.5596 148.757 38.9973 146.338 37.6158C143.832 36.1756 149.165 27.2402 149.573 25.2121C145.406 27.5048 139.024 41.0254 136.984 47.0509C136.605 48.1384 134.536 47.6975 134.595 46.61C134.886 38.9385 140.073 28.269 144.211 24.4479C144.619 24.007 145.755 24.8006 145.289 25.2415Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M154.41 36.3226C156.304 36.352 159.772 30.9731 160.646 27.1226C160.762 26.623 160.762 26.2997 160.209 26.8875C156.858 30.2383 151.758 39.203 152.632 43.9647C152.895 45.4931 154.177 44.9934 155.663 43.8471C159.742 40.6727 164.551 34.7354 168.077 30.8555C168.572 30.4146 169.3 31.0613 168.892 31.5315C166.532 34.2063 158.402 44.288 155.43 45.5519C153.74 46.2867 151.146 45.9634 150.826 43.994C149.922 38.2919 154.818 29.092 158.839 25.7412C160.034 24.7418 162.452 25.8882 161.986 27.6811C161.374 29.9737 157.994 37.9098 154.614 36.8222C154.118 36.6753 153.973 36.3226 154.41 36.3226Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M178.829 42.4362C178.042 43.2886 176.585 41.1136 178.8 40.0848C181.306 38.9679 186.755 37.9979 189.116 37.6452C189.524 37.5864 189.728 37.3807 189.873 37.0574C192.671 31.3258 201.791 12.8966 206.745 10.0455C208.552 9.01675 210.213 10.7215 210.038 12.3381C209.572 16.747 205.871 24.3009 204.064 28.3571C203.86 28.798 202.87 28.2984 203.015 27.8869C204.618 24.0658 207.823 17.2467 208.581 13.1023C209.047 10.5452 208.348 10.9273 206.774 12.4851C202.083 16.3649 196.459 27.3284 191.971 36.6753C191.709 37.2043 192.234 37.0574 192.525 37.028C196.546 36.5283 203.511 35.5583 206.716 35.529C207.357 35.529 207.27 36.8222 206.716 36.8222C202.374 36.9398 196.138 37.9392 191.767 38.527C191.272 38.6152 190.981 38.8209 190.777 39.2618C189.902 42.1423 182.355 57.2502 186.027 58.3671C188.591 59.1313 200.859 55.4278 209.368 53.1646C209.922 53.0176 210.534 53.1646 211.058 53.5761C212.02 54.4285 211.233 55.6042 210.767 55.1045C210.213 54.5166 210.126 54.1639 209.193 54.4578C203.161 56.4566 188.212 61.2182 184.803 59.4546C180.257 57.1032 187.542 43.2298 188.475 40.173C188.708 39.4382 188.766 39.2912 188.271 39.3794C186.085 39.6733 180.257 40.9078 178.829 42.4362ZM226.561 6.95926C227.668 7.31198 228.484 8.811 227.318 9.39886C226.794 9.634 226.357 8.95797 226.648 8.66404C227.085 8.28193 227.318 8.19376 226.648 7.92922C219.479 5.37206 194.332 11.427 189.524 14.2487C188.795 14.6601 189.349 14.7189 189.757 14.7777C191.243 15.0423 190.864 16.4825 189.961 16.3061C187.309 15.8065 187.134 13.749 188.3 12.9554C193.632 9.31068 218.751 4.31392 226.561 6.95926Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M226.735 24.2128C227.231 23.6249 228.892 24.3891 228.513 24.9476C222.423 35.2644 215.691 44.2292 212.981 46.1397C211.262 47.3154 209.484 46.4043 209.28 45.2579C208.727 41.9953 213.36 32.0606 215.167 28.9156C215.75 28.0632 217.556 28.7686 217.061 29.5622C215.866 31.1788 209.28 45.4931 211.495 45.5519C212.836 45.5813 218.489 39.0855 226.735 24.2128Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M229.999 36.3226C231.893 36.352 235.361 30.9731 236.235 27.1226C236.352 26.623 236.352 26.2997 235.798 26.8875C232.447 30.2383 227.347 39.203 228.221 43.9647C228.484 45.4931 229.766 44.9934 231.252 43.8471C235.332 40.6727 240.14 34.7354 243.666 30.8555C244.161 30.4146 244.89 31.0613 244.482 31.5315C242.121 34.2063 233.991 44.288 231.019 45.5519C229.329 46.2867 226.735 45.9634 226.415 43.994C225.511 38.2919 230.407 29.092 234.428 25.7412C235.623 24.7418 238.042 25.8882 237.575 27.6811C236.963 29.9737 233.583 37.9098 230.203 36.8222C229.708 36.6753 229.562 36.3226 229.999 36.3226Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M253.224 48.2266C246.959 46.9333 256.4 28.269 256.779 26.1527C256.895 25.5354 256.429 25.8294 256.167 26.0939C250.047 32.3252 244.19 40.3494 240.81 46.0809C240.315 46.9039 238.391 46.4336 238.479 45.787C239.266 40.9078 242.5 32.0312 245.793 24.4185C246.055 23.8601 247.57 24.5067 247.454 25.0064C244.132 31.943 241.684 38.7328 240.781 42.7008C240.519 43.8765 240.664 43.5238 241.101 42.7889C244.802 37.1749 250.076 30.591 255.934 24.6831C256.924 23.7131 259.168 24.4185 258.906 25.4473C258.119 28.5923 248.853 47.1685 253.69 47.0509C254.593 47.0215 254.04 48.3736 253.224 48.2266Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M272.777 12.8378C272.194 13.8372 271.553 14.9247 270.882 16.1592H279.799C280.44 16.1592 280.12 17.5994 279.595 17.5994H270.067C264.005 28.8568 255.904 47.1979 258.003 53.4585C258.177 54.3109 258.527 54.1639 259.197 53.8994C259.926 53.6349 259.838 55.5748 258.644 55.7217C257.944 55.8099 256.837 55.3396 256.575 53.8406C254.273 47.0803 262.199 29.3859 268.143 17.5994H259.226C258.469 17.5994 258.731 16.1592 259.46 16.1592H268.901C269.659 14.6601 270.387 13.2787 271.028 12.0736C271.524 11.133 273.272 12.0442 272.777 12.8378Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
      <path
        d="M285.686 31.414C282.772 33.2363 279.712 35.0293 276.944 37.028C276.332 37.4689 276.273 37.7922 276.419 38.2625C277.293 41.4369 274.583 46.3455 271.524 48.932C265.987 53.6349 262.84 49.9608 267.619 44.0822C269.717 41.5251 272.631 39.0561 274.612 37.5571C273.592 34.3826 268.755 33.06 268.784 29.9737C268.813 26.1821 273.418 22.3316 276.332 21.5674C277.847 21.1559 279.158 22.0965 278.925 23.3898C278.721 24.3303 277.556 26.9463 276.39 29.2095C276.215 29.5622 275.691 29.2683 275.807 28.945C276.215 27.7987 277.934 22.8607 277.177 22.508C276.215 22.0671 270.766 25.653 270.591 29.8562C270.474 32.4133 273.651 33.6772 275.749 36.5577C280.091 33.5596 282.276 32.237 285.132 30.591C285.569 30.3265 286.123 31.1788 285.686 31.414ZM270.708 47.6975C273.301 45.1698 275.487 40.8784 274.962 38.7621C273.826 39.4088 270.562 42.3481 268.406 45.1698C264.821 49.902 266.57 51.7831 270.708 47.6975Z"
        stroke={color}
        stroke-width="0.6"
        mask="url(#path-1-outside-1_18_80)"
      />
    </svg>
  );
};

export default Title;
